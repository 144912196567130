import { Link } from "gatsby";
import React from "react";
import { Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { navigate } from "gatsby";

export default function ReviewPage({ location }) {
  function handleClickEvent() {
    var access = localStorage.getItem("loginaccesstokec");
    let Type = location.state.typeoffer;
    if (Type === "Buy One Get One") Type = 0;
    else if (Type === "Buy X Get Y") Type = 1;
    else if (Type === "Buy Bundle Get Unit") Type = 2;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access + "");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNet.Cookies=q9mNuHlMaRK2aPgvoaaQrCjBi6XKJjJMwP1agN9G0JUtit2JKdYHn16doXUrMNQ43WNmF6pEARL7i91M7KP0uO6ica1F1s0eOSftlJDsJKdz4RLa8N-E2UPub2bzAKS6BgyDhLfp6UIhT_qrG0VbyuWtsXAfnDu7qmiNrbXQTwx0CgKoBM_a8vw_hTaWszlzdAZ7mTBzUF_kaDugZkMCsw9EgTvBSNhLob2cmfVm73tB-Z6TYLi43mofo9SvITTNYX1ubl7NzSZU9zcwfPMNWU0GttdEOO9GjkoO1obRBCP58xQJdSD1l0re-DryJU0SJiUEC_aTmPHRARK51TLghz6qEUe6J1sYznoaCUkHmImsYaj27G_xpdHZws6Y4Q4_-vi6Mk6RYE12CyFwouCZhaC_QirBKxU0jqpoxaMdiyiQegPL_5pIATluoGAclxTPI8KPcOVEWtoz7R-HzaMmvsiSVpJg4n6ENmfL-VtQy4Ex_z4pJeJp9owadCbaTldS"
    );

    var branches = [];
    (location.state?.Branchs ?? []).forEach((element) => {
      branches.push({ Branch: element });
    });

    var raw = {
      Branches: branches,
      TermsAndConditions: location.state.Description,
      Timings: [],
      Type: {
        Type: Type,
        ToBuy: location.state.buy,
        Bonus: location.state.get,
      },
      ExcludeWeekends: location.state.excludewekkends,
      ExcludeHolidays: location.state.Excludeholidays,
      CustomizeTiming: location.state.CustomizeTiming,
      Savings: location.state.money,
    };

    var array = [
      {
        DayName: "Monday",
        OpenTime: location.state.timeOpenMonday,
        CloseTime: location.state.timeCloseMonday,
      },
      {
        DayName: "Tuesday",
        OpenTime: location.state.timeOpenTuesday1,
        CloseTime: location.state.timeCloseTuesday2,
      },
      {
        DayName: "Wednesday",
        OpenTime: location.state.timeOpenWednesday3,
        CloseTime: location.state.timeCloseWednesday4,
      },
      {
        DayName: "Thursday",
        OpenTime: location.state.timeOpenThursday5,
        CloseTime: location.state.timeCloseThursday6,
      },
      {
        DayName: "Friday",
        OpenTime: location.state.timeOpenFriday7,
        CloseTime: location.state.timeCloseFriday8,
      },
      {
        DayName: "Saturday",
        OpenTime: location.state.TimeOpenSaturday,
        CloseTime: location.state.TimeCloseSaturday,
      },
      {
        DayName: "Sunday",
        OpenTime: location.state.TimeOpenSunday,
        CloseTime: location.state.TimeCloseSunday,
      },
    ];
    array.forEach((element) => {
      if (
        (element.DayName === "Monday" &&
          (location?.state?.valuecheck6 || location?.state?.valuecheck06)) ||
        (element.DayName === "Tuesday" &&
          (location?.state?.valuecheck5 || location?.state?.valuecheck05)) ||
        (element.DayName === "Wednesday" &&
          (location?.state?.valuecheck4 || location?.state?.valuecheck04)) ||
        (element.DayName === "Thursday" &&
          (location?.state?.valuecheck3 || location?.state?.valuecheck03)) ||
        (element.DayName === "Friday" &&
          (location?.state?.valuecheck2 || location?.state?.valuecheck02)) ||
        (element.DayName === "Saturday" &&
          (location?.state?.valuecheck1 || location?.state?.valuecheck01)) ||
        (element.DayName === "Sunday" &&
          (location?.state?.valuecheck7 || location?.state?.valuecheck07))
      )
        raw.Timings.push(element);
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/Merchant/Campaigns`, requestOptions)
      .then((response) => {
        if (response.status === 201) {
          navigate("/dashboard");
        }
        return response?.text() ?? "";
      })
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  }

  return (
    <Layout>
      <main>
        <Container>
          <Row>
            <div className="col-xl-3 col-lg-3 col-md-3 align-self-center">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-5">
                  <li className="breadcrumb-item primary-color">
                    <Link
                      to="/app/dashboard"
                      className="primary-color text-decoration-none"
                    >
                      Campaigns
                    </Link>
                  </li>
                  <li className="breadcrumb-item primary-color">
                    <Link
                      to="javascript:void(0)"
                      onClick={() => navigate(-1)}
                      className="primary-color text-decoration-none"
                    >
                      Create New
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item primary-color"
                    aria-current="page"
                  >
                    Review
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 text-center">
              <h1 className="fs-48 fw-800 mb-5">Review your campaign</h1>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 text-end pt-3">
              <Link
                to=""
                className="text-dark fw-airlight text-decoration-none"
              >
                <span className="mb-4 align-text-bottom">
                  {" "}
                  <img src="../img/Tuto.svg" alt="btn tuto" className="me-2" />
                </span>
                Watch tutorial
              </Link>
            </div>
          </Row>
          <section className="--Title">
            <div className="col-xl-3 col-lg-3 col-md-12 mx-auto mt-4 mb-5 text-center">
              <p className="text-muted fs-13">
                This is how your campaign will appear to customers
              </p>
            </div>
          </section>
          <secion className="Card-Ticket">
            <div className="col-xl-3 col-lg-4 col-md-6 mx-auto ticket-review">
              <Card className="">
                <Card.Body className="review">
                  <Row>
                    <Col xl="8" lg="8" md="6" xs="6">
                      <Row>
                        <Col xl="4" lg="4" md="5" xs="6">
                          <img
                            src="../img/Logo-ticket.svg"
                            alt="logo"
                            className="me-2"
                          />
                        </Col>
                        <Col xl="8" lg="8" md="7" xs="6">
                          <p className="text-muted fs-8 mb-0"> BUY</p>
                          <p className="fs-10 mb-1">{location?.state?.buy}</p>
                          <p className="text-muted fs-8 mb-0"> GET</p>
                          <p className="fs-10 mb-0">{location?.state?.get}</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xl="4" lg="4" md="6" xs="6" className="text-end">
                      <p className="text-muted fs-8 mb-0">VALIDITY</p>
                      <span className="d-flex justify-content-end">
                        <span
                          className={
                            " pt-0 ps-0 pe-1  fs-8 mb-0" +
                            " value" +
                            location?.state?.valuecheck7 +
                            " value" +
                            location?.state?.valuecheck07
                          }
                        >
                          S
                        </span>
                        <span
                          className={
                            " pt-0 ps-0 pe-1  fs-8 mb-0" +
                            " value" +
                            location?.state?.valuecheck6 +
                            " value" +
                            location?.state?.valuecheck06
                          }
                        >
                          {" "}
                          M
                        </span>
                        <span
                          className={
                            " pt-0 ps-0 pe-1  fs-8 mb-0" +
                            " value" +
                            location?.state?.valuecheck5 +
                            " value" +
                            location?.state?.valuecheck05
                          }
                        >
                          {" "}
                          T
                        </span>
                        <span
                          className={
                            " pt-0 ps-0 pe-1  fs-8 mb-0" +
                            " value" +
                            location?.state?.valuecheck4 +
                            " value" +
                            location?.state?.valuecheck04
                          }
                        >
                          {" "}
                          W{" "}
                        </span>
                        <span
                          className={
                            " pt-0 ps-0 pe-1  fs-8 mb-0" +
                            " value" +
                            location?.state?.valuecheck3 +
                            " value" +
                            location?.state?.valuecheck03
                          }
                        >
                          {" "}
                          T
                        </span>
                        <span
                          className={
                            " pt-0 ps-0 pe-1  fs-8 mb-0" +
                            " value" +
                            location?.state?.valuecheck2 +
                            " value" +
                            location?.state?.valuecheck02
                          }
                        >
                          {" "}
                          F{" "}
                        </span>
                        <span
                          className={
                            " pt-0 ps-0 pe-1  fs-8 mb-0" +
                            " value" +
                            location?.state?.valuecheck +
                            " value" +
                            location?.state?.valuecheck01
                          }
                        >
                          {" "}
                          S
                        </span>
                      </span>
                      <p className="text-muted fs-8 mb-0"> EST. SAVINGS</p>
                      <p className="primary-color fs-10 mb-0">
                        AED <span>{location?.state?.money}</span>
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="bg-white border-bottom pt-3">
                  <Col xl="12">
                    <Row>
                      <Col xl="5" lg="5" md="6" xs="6">
                        <p className="fs-12 fw-airbold mb-1">VOUCHER CODE</p>
                        <p className="primary-color fs-14 mb-1">BOGO128173</p>
                      </Col>
                      <Col xl="7" lg="7" md="6" xs="6">
                        <p className="fs-12 fw-airbold mb-1">MERCHANT PIN</p>
                        <Form className="d-flex">
                          <Form.Group className="mb-3" controlId="formPin">
                            <InputGroup.Text>-</InputGroup.Text>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formPin">
                            <InputGroup.Text>-</InputGroup.Text>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formPin">
                            <InputGroup.Text>-</InputGroup.Text>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formPin">
                            <InputGroup.Text>-</InputGroup.Text>
                          </Form.Group>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                </Card.Footer>
                <Card.Footer className="bg-white">
                  <Col xl="12">
                    <Row>
                      <Col xl="5" lg="5" md="6" xs="6">
                        <p className="fs-12 fw-airbold mb-2">VALIDITY</p>
                        <p
                          className={
                            "row fs-14 mb-0" +
                            " value" +
                            location?.state?.valuecheck7 +
                            " value" +
                            location?.state?.valuecheck07
                          }
                        >
                          <span className="col-2">S</span>
                          <span className="col-9 text-dark fw-airlight ps-2">
                            {location?.state?.TimeOpenSunday}-
                            {location?.state?.TimeCloseSunday}
                          </span>
                        </p>

                        <p
                          className={
                            "row fs-14 mb-0" +
                            " value" +
                            location?.state?.valuecheck6 +
                            " value" +
                            location?.state?.valuecheck06
                          }
                        >
                          <span className="col-2">M</span>
                          <span className="col-9 text-dark fw-airlight ps-2">
                            {location?.state?.timeOpenMonday}-
                            {location?.state?.timeCloseMonday}
                          </span>
                        </p>
                        <p
                          className={
                            "row fs-14 mb-0" +
                            " value" +
                            location?.state?.valuecheck5 +
                            " value" +
                            location?.state?.valuecheck05
                          }
                        >
                          <span className="col-2">T</span>
                          <span className="col-9 text-dark fw-airlight ps-2">
                            {location?.state?.timeOpenTuesday1}-
                            {location?.state?.timeCloseTuesday2}
                          </span>
                        </p>
                        <p
                          className={
                            "row fs-14 mb-0" +
                            " value" +
                            location?.state?.valuecheck4 +
                            " value" +
                            location?.state?.valuecheck04
                          }
                        >
                          <span className="col-2">W</span>
                          <span className="col-9 text-dark fw-airlight ps-2">
                            {location?.state?.timeOpenWednesday3}-
                            {location?.state?.timeCloseWednesday4}
                          </span>
                        </p>
                        <p
                          className={
                            "row fs-14 mb-0" +
                            " value" +
                            location?.state?.valuecheck3 +
                            " value" +
                            location?.state?.valuecheck03
                          }
                        >
                          <span className="col-2">T</span>
                          <span className="col-9 text-dark fw-airlight ps-2">
                            {location?.state?.timeOpenThursday5}-
                            {location?.state?.timeCloseThursday6}
                          </span>
                        </p>
                        <p
                          className={
                            "row fs-14 mb-0" +
                            " value" +
                            location?.state?.valuecheck2 +
                            " value" +
                            location?.state?.valuecheck02
                          }
                        >
                          <span className="col-2">F</span>
                          <span className="col-9 text-dark fw-airlight ps-2">
                            {location?.state?.timeOpenFriday7}-
                            {location?.state?.timeCloseFriday8}
                          </span>
                        </p>

                        <p
                          className={
                            "row fs-14 mb-0" +
                            " value" +
                            location?.state?.valuecheck +
                            " value" +
                            location?.state?.valuecheck01
                          }
                        >
                          <span className="col-2">S</span>
                          <span className="col-9 text-dark fw-airlight ps-2">
                            {location?.state?.TimeOpenSaturday}-
                            {location?.state?.TimeOpenSaturday}
                          </span>
                        </p>
                      </Col>
                      <Col xl="6" lg="6" md="6" xs="6">
                        <p className="fs-12 fw-airbold mb-2">FINE PRINT</p>
                        <p className="fw-airlight fs-10 lh-30">
                          {location?.state?.Description}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl="12">
                    <Row>
                      <div className="my-toggle-btn-wrapper mt-4">
                        <div className="my-toggle-btn">
                          <input type="checkbox" id="checkbox1" />
                          <label for="checkbox1">
                            <span className="on fs-13 fw-airbold">
                              Slide to redeem
                            </span>
                            <span className="off fs-13 fw-airbold">
                              Slide to redeem
                            </span>
                          </label>
                        </div>
                      </div>
                    </Row>
                  </Col>
                </Card.Footer>
              </Card>
            </div>
          </secion>
          <section className="--Title">
            <div className="col-xl-3 col-lg-3 col-md-12 mx-auto mt-4 mb-5 text-center">
              <p className="text-muted fs-13">
                By creating a campaign, you agree to BOGO’s{" "}
                <span className="ps-2">
                  <Link
                    className="primary-color text-decoration-none fw-airbold"
                    to="/terms"
                  >
                    Terms and Conditions{" "}
                  </Link>
                </span>
                and{" "}
                <span>
                  <Link
                    className="primary-color text-decoration-none fw-airbold"
                    to="/terms#privacy"
                  >
                    Privacy Policy
                  </Link>
                </span>
              </p>
            </div>
          </section>
          <section className="--Submit mt-5">
            <div className="col-xl-3 col-lg-3 col-md-12 mx-auto mt-4 mb-5 text-center">
              <button
                className="btn btn-primary py-3 w-100 fs-14 mb-3"
                type="submit"
                onClick={handleClickEvent}
              >
                Publish Campaign
              </button>
              <Link to="" className="primary-color fs-14  text-decoration-none">
                Save Draft
              </Link>
            </div>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
